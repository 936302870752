import { SelectableValue } from '@grafana/data';
import { NetworkMonitor } from './types';

export async function asyncGetAnalyticsOption() {
  let response = await fetch(`/api/epg_fr/monitor_types/`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
  // rejected:
  if (!response.ok) {
    console.error(`Error getting Analytics Types: error code: ${response.status}; description: ${response.statusText}`);
    return [];
  }
  // fullfilled:
  const data = await response.json();
  if (!data?.data?.monitor_types) {
    console.error(`Error getting Analytics Types: no valid Analytics Types in response body.`);
    return [];
  }
  const analyticsFromAPI: Array<{ id: string; display_string: string }> = data.data.monitor_types;
  let analyticsOptions: Array<SelectableValue<string>> = [];
  analyticsFromAPI.forEach(analytics => {
    analyticsOptions.push({ label: analytics.display_string, value: analytics.id });
  });
  return analyticsOptions;
}

export async function asyncGetMonitors() {
  const monitorURL = `/api/epg_fr/monitors/`;
  let tempMonitors: NetworkMonitor[] = [];
  const response = await fetch(`${monitorURL}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  });
  // rejected
  if (!response.ok) {
    if (response.status === 401) {
      throw new Error('Unauthorized');
    } else {
      return asyncGetMonitorsFind();
    }
  } 
  // fullfilled
  const data = await response.json();
  if (!data) {
    console.error(`Error getting Network Monitors: no valid Network Monitors in response body.`);
    return [];
  }
  const monitorsFromAPI: any[] = data.data;
  if (monitorsFromAPI.length === 0 || !monitorsFromAPI[0].cidr) {
      return asyncGetMonitorsFind();
    } else {
      monitorsFromAPI.forEach(monitor => {
        tempMonitors.push({ name: monitor.name, cidr: monitor.cidr });
    });
    return tempMonitors;
  }
}

export async function asyncGetMonitorsFind() {
  const monitorURL = `/rt/data/find`;
  let tempMonitors: NetworkMonitor[] = [];
  const response = await fetch(`${monitorURL}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      collection: 'endpointgroup',
    }),
  });
  // rejected
  if (!response.ok) {
    console.error(`Error getting Network Monitors: error code: ${response.status}; description: ${response.statusText}`);
    return [];
  } 
  // fullfilled
  const data = await response.json();
  if (!data) {
    console.error(`Error getting Network Monitors: no valid Network Monitors in response body.`);
    return [];
  }
  const monitorsFromAPI: any[] = data;
  monitorsFromAPI.forEach(monitor => {
    tempMonitors.push({ name: monitor.name, cidr: monitor.epg_identifier.cidr });
  });
  return tempMonitors;
}

export async function asyncGetCstors() {
  const cstorURL = `/cstor/get_cstors/`;
  let tempCstors: Array<SelectableValue<string>> = [];
  const response = await fetch(`${cstorURL}`);
  // rejected
  if (!response.ok) {
    console.error(`Error getting cStors: error code: ${response.status}; description: ${response.statusText}`);
    return [];
  }
  // fullfilled
  const data = await response.json();
  if (!data || !data.cstors) {
    console.error(`Error getting cStors: no valid Network Monitors in response body.`);
    return [];
  }
  const cStorsFromAPI: any[] = data.cstors;
  cStorsFromAPI.forEach(cstor => {
    tempCstors.push({ label: cstor[1], value: cstor[1] });
  });
  return tempCstors;
}
