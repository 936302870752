import { SelectableValue } from '@grafana/data';
import { RawData } from '../types';

export const parseEntities = (dataList: RawData[]) => {
  const entities = dataList.reduce((acc, current) => {
    const separated =
      current.name &&
      current.name
        .split('><')
        .map((part: string) => part.replace(/[<>]/g, ''))
        // Empty strings and undefined are falsy, remove with a cast with filter
        .map((entity: string) => entity.split(/_(.+)/).filter(Boolean));
    separated &&
      acc.push(
        separated.filter((item: string[]) => {
          return item[1] !== 'undefined';
        })
      );
    return acc;
  }, [] as string[][][]);

  let monitors: Array<SelectableValue<string>> = [];
  let cstors: Array<SelectableValue<string>> = [];
  entities.forEach((element: string[][]) => {
    const monitor = element.find(element => element[0] === 'MONITOR');
    const cstor = element.find(element => element[0] === 'CSTOR');
    if (monitor) {
      monitors.push({
        label: monitor[1],
        value: monitor[1],
      });
    }
    if (cstor) {
      cstors.push({
        label: cstor[1],
        value: cstor[1],
      });
    }
  });
  return [cstors, monitors];
};

export const checkQueryResponseValidity = (dataList: RawData[], monitorQuery: boolean, cstorQuery: boolean) => {
  const usesDefaultQuery = !!dataList[0] && dataList[0].alias === 'A-series';
  const emptyResults =
    !!dataList &&
    (dataList.length === 0 ||
      (!!dataList[0].name && monitorQuery && /<MONITOR_>$/g.test(dataList[0].name)) ||
      (!!dataList[0].name && cstorQuery && /<CSTOR_>$/g.test(dataList[0].name)));
  const resultsInTable = !!dataList[0] && 'columns' in dataList[0] && 'rows' in dataList[0];
  const incorrectMonitorResults = monitorQuery && (emptyResults || !dataList[0].name || !/(<(.*?)>){1,}/g.test(dataList[0].name) || !/<MONITOR_(?!undefined>)/g.test(dataList[0].name))
  const incorrectCstorResults = cstorQuery && (emptyResults || !dataList[0].name || !/(<(.*?)>){1,}/g.test(dataList[0].name) || !/<CSTOR_(?!undefined>)/g.test(dataList[0].name));
  const queryWarning = {
    usesDefaultQuery,
    emptyResults,
    resultsInTable,
    incorrectMonitorResults,
    incorrectCstorResults,
  };

  return queryWarning;
};
