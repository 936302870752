import { DownloadResponse, DownloadStatus, NetworkMonitor, ParameterConfig, Selections, SimpleOptions } from 'ondemand/types';
import {
  RESPONSE_PROPERTIES,
  RESPONSE_MESSAGE,
  DOWNLOAD_STATES,
  RESPONSE_KEY,
  DOWNLOAD_STATUS_PROPERTIES,
  UNIQUE_NAME_NM_PREFIX,
  LayoutType,
} from 'ondemand/constants';
import React, { useRef, useState, useEffect } from 'react';
import { Button, ConfirmModal, LoadingPlaceholder } from '@grafana/ui';
import { WithStyles } from 'ondemand/components/hoc/WithStyles';
import { Styles } from 'types';
import { SelectableValue, TimeRange } from '@grafana/data';

interface Props {
  timeRange: TimeRange;
  selections: Selections;
  enabledConfigs: ParameterConfig;
  states: DOWNLOAD_STATES;
  setStates: Function;
  setDownloadResponse: Function;
  setDownloadStatus: Function;
  timeElapsed: number;
  setTimeElapsed: Function;
  toState: Function;
  monitorsFromCclear: NetworkMonitor[];
  styles: Styles;
  options: SimpleOptions;
}

const BaseDownload: React.FC<Props> = ({
  timeRange,
  selections,
  enabledConfigs,
  states,
  setStates,
  setDownloadResponse,
  setDownloadStatus,
  timeElapsed,
  setTimeElapsed,
  toState,
  monitorsFromCclear,
  styles,
  options,
}) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const timer = useRef<number>();
  const [timePrompt, setTimePrompt] = useState('');
  const onDownloadClick = () => {
    switch (states) {
      case DOWNLOAD_STATES.PRE_READY:
      case DOWNLOAD_STATES.COMPLETE:
      case DOWNLOAD_STATES.INITIALIZED:
        download();
        break;
      case DOWNLOAD_STATES.PRE_WARNING:
        const range = timeRange.to.valueOf() - timeRange.from.valueOf();
        if (range > 5 * 60 * 1000) {
          setTimePrompt(Math.round(range / 1000) / 60 > 60
            ? `${(Math.round(range / 1000) / 60 / 60).toFixed(1)} hours`
            : `${(Math.round(range / 1000) / 60).toFixed(1)} minutes`);
          setConfirmOpen(true);
        } else {
          download();
        }
        break;
      default:
    }
  };
  const download = () => {
    // reset time counter
    setTimeElapsed(0);
    // gather parameters
    const from = timeRange.from;
    const to = timeRange.to;
    const aType = enabledConfigs.analyticsType ? selections.analyticsType?.value : '';
    const nmName = enabledConfigs.nmName ? selections.nmName?.label : '';
    const cstorNames: string[] = enabledConfigs.cStorName
      ? selections.cStorName
        ? selections.cStorName.map((cstor: SelectableValue<string>) => cstor.label as string)
        : []
      : [];
    let cidr = enabledConfigs.cstorCidr && selections.cstorCidr;
    if (enabledConfigs.nmName) {
      if (!!selections.nmCidr && LayoutType.IPCIDR_WITH_NETWORK_MONITOR === options.ltType) {
        cidr = selections.nmCidr;
      } else {
        const foundCidr = monitorsFromCclear.find(nm => nm.name === nmName);
        cidr = foundCidr && foundCidr.cidr.length > 0 ? foundCidr.cidr[0] : '';
      }
    }
    const params = JSON.stringify({
      start_time: from.valueOf(),
      end_time: to.valueOf(),
      network_monitor_type: aType,
      network_monitor_name: nmName,
      cstor_name: cstorNames,
      cidr: cidr,
    });
    const un_nm = enabledConfigs.nmName ? nmName : UNIQUE_NAME_NM_PREFIX;
    const uniqueName = `${un_nm}:${cidr}:${from.valueOf() / 1000}:${to.valueOf() / 1000}`;
    const equalCstors = (arrCstors1: string[], arrCstors2: string[]) => {
      if (!arrCstors1 || !arrCstors2 || (arrCstors1.length !== arrCstors2.length)) {
        return false;
      } else {
        arrCstors1.sort();
        arrCstors2.sort();
        return arrCstors1.every((cstor, index) => cstor === arrCstors2.at(index));
      }
    }
    // start downloading
    window
      .fetch('/api/epg_fr/on_demand_monitor/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: params,
      })
      .then((resp: Response) => {
        if (resp.status === 401){
          toState(DOWNLOAD_STATES.DISCONNECT, {
            [DOWNLOAD_STATUS_PROPERTIES.status as keyof DownloadStatus]: `${DOWNLOAD_STATES.DISCONNECT}. Please login to a configured cClear.`,
            [DOWNLOAD_STATUS_PROPERTIES.color as keyof DownloadStatus]: styles.downloadError,
          });
          return undefined;
        }
        return resp.json();
      })
      .then(responseData => {
        if (responseData.status.toUpperCase() === RESPONSE_MESSAGE.STATUS_SUCCESS) {
          const download = responseData.data[RESPONSE_KEY.onDemandNM].find((element: { [x: string]: string }) => {
            try {
              // time
              let isFound =
                Number(element[RESPONSE_KEY.startTime]) * 1000 === from.valueOf() &&
                Number(element[RESPONSE_KEY.endTime]) * 1000 === to.valueOf();
              // nm
              if (enabledConfigs.nmName) {
                isFound = isFound && element[RESPONSE_KEY.nmName] === nmName;
              }
              // cidr
              if (!!cidr) {
                isFound =
                  isFound && ((element[RESPONSE_KEY.cidr] as unknown) as string[])[0] === cidr;
              }
              // cstor
              if (enabledConfigs.cStorName) {
                isFound =
                  isFound && equalCstors((element[RESPONSE_KEY.cstor] as unknown) as string[], cstorNames);
              }
              if (isFound) {
                return element;
              }
            } catch (error) {
              // do nothing. Would return undefined instead
            }
            return undefined;
          });
          if (download) {
            // found matching download
            setDownloadResponse({
              [RESPONSE_PROPERTIES.uniqueName as keyof DownloadResponse]: download[RESPONSE_KEY.uniqueName],
              [RESPONSE_PROPERTIES.startTime as keyof DownloadResponse]: download[RESPONSE_KEY.startTime],
              [RESPONSE_PROPERTIES.endTime as keyof DownloadResponse]: download[RESPONSE_KEY.endTime],
              [RESPONSE_PROPERTIES.nmName as keyof DownloadResponse]: download[RESPONSE_KEY.nmName],
              [RESPONSE_PROPERTIES.cstor as keyof DownloadResponse]: download[RESPONSE_KEY.cstor],
              [RESPONSE_PROPERTIES.cstorIP as keyof DownloadResponse]: download[RESPONSE_KEY.cstorIP],
              [RESPONSE_PROPERTIES.cidr as keyof DownloadResponse]: download[RESPONSE_KEY.cidr],
            });
            const status = download[RESPONSE_KEY.status];
            toState(DOWNLOAD_STATES.DOWNLOAD_SUCCESS, {
              [DOWNLOAD_STATUS_PROPERTIES.started as keyof DownloadStatus]: download[RESPONSE_KEY.started],
              [DOWNLOAD_STATUS_PROPERTIES.completed as keyof DownloadStatus]: download[RESPONSE_KEY.completed],
              [DOWNLOAD_STATUS_PROPERTIES.status as keyof DownloadStatus]: `${status
                .charAt(0)
                .toUpperCase()}${status.slice(1)}`,
              [DOWNLOAD_STATUS_PROPERTIES.color as keyof DownloadStatus]: styles.downloadStatus,
            });
          } else {
            toState(DOWNLOAD_STATES.DOWNLOAD_FAIL, {
              [DOWNLOAD_STATUS_PROPERTIES.status as keyof DownloadStatus]: RESPONSE_MESSAGE.NO_MATCHING_DOWNLOAD,
              [DOWNLOAD_STATUS_PROPERTIES.color as keyof DownloadStatus]: styles.downloadError,
            });
          }
        } else {
          if (responseData.data && responseData.data[RESPONSE_KEY.reason]) {
            // fail with a "reason"
            const reason: string = responseData.data[RESPONSE_KEY.reason];
            if (reason.includes(RESPONSE_MESSAGE.ALREADY_SCHEDULED)) {
              setDownloadResponse((prev: DownloadResponse) => {
                return {
                  ...prev,
                  [RESPONSE_PROPERTIES.uniqueName as keyof DownloadResponse]: uniqueName,
                  [RESPONSE_PROPERTIES.startTime as keyof DownloadResponse]: from,
                  [RESPONSE_PROPERTIES.endTime as keyof DownloadResponse]: to,
                  [RESPONSE_PROPERTIES.nmName as keyof DownloadResponse]: nmName,
                  [RESPONSE_PROPERTIES.cstor as keyof DownloadResponse]: cstorNames,
                  [RESPONSE_PROPERTIES.cstorIP as keyof DownloadResponse]: [],
                  [RESPONSE_PROPERTIES.cidr as keyof DownloadResponse]: [cidr],
                };
              });
              toState(DOWNLOAD_STATES.DOWNLOAD_SUCCESS, {
                [DOWNLOAD_STATUS_PROPERTIES.started as keyof DownloadStatus]: true,
                [DOWNLOAD_STATUS_PROPERTIES.completed as keyof DownloadStatus]: false,
                [DOWNLOAD_STATUS_PROPERTIES.status as keyof DownloadStatus]: RESPONSE_MESSAGE.PREVIOUSLY_SCHEDULED,
                [DOWNLOAD_STATUS_PROPERTIES.color as keyof DownloadStatus]: styles.downloadStatus,
              });
            } else {
              toState(DOWNLOAD_STATES.DOWNLOAD_FAIL, {
                [DOWNLOAD_STATUS_PROPERTIES.status as keyof DownloadStatus]: reason,
                [DOWNLOAD_STATUS_PROPERTIES.color as keyof DownloadStatus]: styles.downloadError,
              });
            }
          } else {
            toState(DOWNLOAD_STATES.DOWNLOAD_FAIL, {
              [DOWNLOAD_STATUS_PROPERTIES.status as keyof DownloadStatus]: `Failed to create a download task.`,
              [DOWNLOAD_STATUS_PROPERTIES.color as keyof DownloadStatus]: styles.downloadError,
            });
          }
        }
      })
      .catch(e => {
        console.error(e);
        toState(DOWNLOAD_STATES.DOWNLOAD_FAIL, {
          [DOWNLOAD_STATUS_PROPERTIES.status as keyof DownloadStatus]: `Error: ${e}`,
          [DOWNLOAD_STATUS_PROPERTIES.color as keyof DownloadStatus]: styles.downloadError,
        });
      });
  };

  useEffect(() => {
    // start time counter
    if (states === DOWNLOAD_STATES.DOWNLOAD_SUCCESS) {
      timer.current = window.setInterval(() => {
        setTimeElapsed((prevTimeElapsed: number) => prevTimeElapsed + 1);
      }, 1000);
    }
    ![DOWNLOAD_STATES.DOWNLOAD_SUCCESS, DOWNLOAD_STATES.DOWNLOAD_STATUS].includes(states) &&
      timer &&
      window.clearInterval(timer.current);
  }, [setTimeElapsed, states]);

  return (
    <div>
      {[
        DOWNLOAD_STATES.INITIALIZED,
        DOWNLOAD_STATES.PRE_ERROR,
        DOWNLOAD_STATES.PRE_WARNING,
        DOWNLOAD_STATES.PRE_READY,
        DOWNLOAD_STATES.DISCONNECT,
        DOWNLOAD_STATES.DOWNLOAD_FAIL,
        DOWNLOAD_STATES.COMPLETE,
      ].includes(states) ? (
        <div>
          <Button
            size="md"
            onClick={onDownloadClick.bind(this)}
            icon="download-alt"
            disabled={[DOWNLOAD_STATES.PRE_ERROR, DOWNLOAD_STATES.DISCONNECT, DOWNLOAD_STATES.DOWNLOAD_FAIL,
            DOWNLOAD_STATES.DOWNLOAD_SUCCESS, DOWNLOAD_STATES.DOWNLOAD_STATUS].includes(
              states
            )}
          >
            Fetch Analytics
          </Button>
          <ConfirmModal
            isOpen={confirmOpen}
            title={'Timerange > 5m'}
            body={`Are you sure you want to collect ${timePrompt} of data?`}
            icon={'question-circle'}
            confirmText={'Confirm'}
            onConfirm={() => {
              setConfirmOpen(false);
              download();
            }}
            onDismiss={() => {
              setConfirmOpen(false);
            }}
          ></ConfirmModal>
        </div>
      ) : null}
      {DOWNLOAD_STATES.DOWNLOAD_SUCCESS === states ? (
        <LoadingPlaceholder text={'Will start monitor collecting status...'} />
      ) : null}
      {DOWNLOAD_STATES.DOWNLOAD_STATUS === states ? (
        <LoadingPlaceholder text={'In Progress...' + timeElapsed + 's...'} />
      ) : null}
    </div>
  );
};

export const Download = WithStyles(BaseDownload);
