import { HorizontalGroup, Label, VerticalGroup } from '@grafana/ui';
import { WithStyles } from 'ondemand/components/hoc/WithStyles';
import { DownloadResponse, DownloadStatus } from 'ondemand/types';
import React from 'react';
import { Styles } from 'types';

interface Props {
  title: string;
  downloadResponse: DownloadResponse;
  downloadStatus: DownloadStatus;
  styles: Styles;
}

const BaseQueue: React.FC<Props> = ({ title, downloadResponse, downloadStatus, styles }) => {
  return (
    <VerticalGroup>
      <HorizontalGroup justify="center">
        <h5>{title}</h5>
      </HorizontalGroup>
      <HorizontalGroup justify="flex-start">
        <Label>Timerange: </Label>
        <Label className={styles.downloadQueue}>{`${Math.round(
          downloadResponse.endTime - downloadResponse.startTime
        )}s`}</Label>
      </HorizontalGroup>
      <HorizontalGroup justify="flex-start">
        <Label>Network Monitor: </Label>
        <Label className={styles.downloadQueue}>{downloadResponse.nmName}</Label>
      </HorizontalGroup>
      <HorizontalGroup justify="flex-start">
        <Label>cStors: </Label>
        <Label className={styles.downloadQueue}>{downloadResponse.cstor.toString()}</Label>
      </HorizontalGroup>
      <HorizontalGroup justify="flex-start">
        <Label>IP/CIDR: </Label>
        <Label className={styles.downloadQueue}>{downloadResponse.cidr.toString()}</Label>
      </HorizontalGroup>
      <HorizontalGroup justify="flex-start">
        <Label>Status: </Label>
        <Label className={styles.downloadQueueStatus}>{`${downloadStatus.status
          .charAt(0)
          .toUpperCase()}${downloadStatus.status.slice(1)}`}</Label>
      </HorizontalGroup>
    </VerticalGroup>
  );
};

export const Queue = WithStyles(BaseQueue);
