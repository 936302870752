import React from 'react';
import { Alert } from '@grafana/ui';

import { Styles, QueryWarning } from 'types';
import { WARNING_TOOLTIPS } from 'ondemand/constants';
import { WithStyles } from 'ondemand/components/hoc/WithStyles';
import { ParameterConfig, Selections, Validation } from 'ondemand/types';

interface Props {
  queryResultsValid: boolean;
  queryWarning: QueryWarning;
  styles: Styles;
  validation: Validation;
  enabledConfigs: ParameterConfig;
  selections: Selections;
}

type WarningSet = {
  [key: string]: boolean;
};

const BaseErrorWarnings: React.FC<Props> = ({
  queryResultsValid,
  queryWarning,
  styles,
  validation,
  enabledConfigs,
  selections,
}) => {
  const validationWarnings = {
    emptyAnalytics: enabledConfigs.analyticsType ? !validation.analyticsType : false,
    emptyNMName: enabledConfigs.nmName ? !validation.nmName : false,
    emptyCstor: enabledConfigs.cStorName ? !validation.cStorName : false,
    emptyCidr: enabledConfigs.cstorCidr ? !validation.notEmptyCstorCidr : false,
    invalidCidr: enabledConfigs.nmCidr
      ? !validation.nmCidr
      : enabledConfigs.cstorCidr
      ? !validation.cstorCidr
      : false,
  };
  const hasValidationError = Object.values(validationWarnings).includes(true);

  const renderWarnings = (warnings: WarningSet) => {
    return Object.entries(warnings).map(warning => {
      const isTrue = warning[1];
      const warningKey = warning[0];

      return isTrue ? <Alert title={WARNING_TOOLTIPS[warningKey]} severity="warning" key={warningKey} /> : null;
    });
  };

  return (
    <div className={styles.errorWrapper}>
      {!queryResultsValid && renderWarnings(queryWarning)}
      {hasValidationError && renderWarnings(validationWarnings)}
    </div>
  );
};

export const ErrorWarnings = WithStyles(BaseErrorWarnings);
