import { PanelPlugin } from '@grafana/data';
import {
  ANALYTICS_TYPE_OPTION_CONFIG,
  CSTOR_NAME_OPTION_CONFIG,
  NMNAME_OPTION_CONFIG,
  LayoutType,
  LAYOUT_TYPE_OPTION_CONFIG,
  CIDR_OPTION_CONFIG,
} from './constants';
import { SimpleOptions } from './types';
import { asyncGetAnalyticsOption } from './apiUtils';
import { OndemandPanel } from './views/Panel';

export const plugin = new PanelPlugin<SimpleOptions>(OndemandPanel).setPanelOptions(builder => {
  return builder
    .addRadio({
      name: LAYOUT_TYPE_OPTION_CONFIG.name,
      path: LAYOUT_TYPE_OPTION_CONFIG.path,
      defaultValue: LayoutType.NETWORK_MONITOR,
      category: ['Display'],
      settings: {
        allowCustomValue: false,
        options: Object.entries(LayoutType).map(([key, value]) => {
          return { label: value, value: value };
        }),
      },
    })
    .addSelect({
      path: ANALYTICS_TYPE_OPTION_CONFIG.path,
      name: ANALYTICS_TYPE_OPTION_CONFIG.name,
      defaultValue: '',
      category: ['Display'],
      description: 'To set the same analytics type for this panel, select one and save the dashboard.',
      settings: {
        allowCustomValue: false,
        options: [],
        getOptions: asyncGetAnalyticsOption,
      },
      showIf: currentConfig => {
        return currentConfig.ltType === LayoutType.ANALYTICS_IPCIDR_WITH_CSTOR;
      },
    })
    .addTextInput({
      path: NMNAME_OPTION_CONFIG.path,
      name: NMNAME_OPTION_CONFIG.name,
      description: 'To bind Network Monitor to a template variable, tie it in this format: ${variable_name}.',
      defaultValue: '',
      category: ['Display'],
      showIf: config => {
        return config.ltType === LayoutType.NETWORK_MONITOR || config.ltType === LayoutType.IPCIDR_WITH_NETWORK_MONITOR;
      },
    })
    .addTextInput({
      path: CSTOR_NAME_OPTION_CONFIG.path,
      name: CSTOR_NAME_OPTION_CONFIG.name,
      description: 'To bind cStor to a template variable, tie it in this format: ${variable_name}.',
      defaultValue: '',
      category: ['Display'],
      showIf: config => {
        return config.ltType === LayoutType.ANALYTICS_IPCIDR_WITH_CSTOR;
      },
    })
    .addTextInput({
      path: CIDR_OPTION_CONFIG.path,
      name: CIDR_OPTION_CONFIG.name,
      description: 'An IP or CIDR of the Network Monitor with data to collect. To bind it to a template variable, tie it in this format: ${variable_name}.',
      defaultValue: '',
      category: ['Display'],
      showIf: config => {
        return (
          config.ltType === LayoutType.IPCIDR_WITH_NETWORK_MONITOR ||
          config.ltType === LayoutType.ANALYTICS_IPCIDR_WITH_CSTOR
        );
      },
    });
});

