import {
  APIKeyType,
  ConfigOption,
  DownloadResponse,
  DownloadStatus,
  ParameterConfig,
  QueryWarning,
  Selections,
  Validation,
} from './types';

export const REGEX_ALL_IPS = '.*';

export const DOWNLOAD_REQUEST_LABEL = {
  startTime: 'Start Time: ',
  endTime: 'End Time: ',
  timeRange: 'Time Range: ',
  ipCider: 'IP/CIDR: ',
  analyticsType: 'Analytics Type:',
  cStor: 'cStor: ',
  nmName: 'Network Monitor: ',
};

export const DEFAULT_VALIDATION: Validation = {
  analyticsType: true,
  nmName: true,
  cStorName: true,
  nmCidr: true,
  cstorCidr: true,
  notEmptyCstorCidr: false,
};

export const DEFAULT_SELECTIONS: Selections = {
  analyticsType: undefined,
  cStorName: undefined,
  cstorCidr: '0.0.0.0/0',
  nmName: undefined,
  nmCidr: '0.0.0.0/0',
};

export const DEFAULT_ENABLED_CONFIGS: ParameterConfig = {
  analyticsType: false,
  nmName: false,
  cStorName: false,
  nmCidr: false,
  cstorCidr: false,
};

export const LAYOUT_TYPE_OPTION_CONFIG: ConfigOption = {
  label: 'Panel Layout Type',
  name: 'Panel Layout Type:',
  path: 'ltType',
};

export const ANALYTICS_TYPE_OPTION_CONFIG: ConfigOption = {
  label: 'Analytics Type: ',
  name: 'Analytics Type:',
  path: 'analyticsType',
};

export const NMNAME_OPTION_CONFIG: ConfigOption = {
  label: 'Network Monitor:',
  name: 'Network Monitor Name:',
  path: 'nmName',
};

export const CSTOR_NAME_OPTION_CONFIG: ConfigOption = {
  label: 'cStor Name: ',
  name: 'cStor Name:',
  path: 'cStorName',
};

export const CIDR_OPTION_CONFIG: ConfigOption = {
  label: 'IP/CIDR: ',
  name: 'IP/CIDR:',
  path: 'cidr',
};

export const REQUEST_PROPERTIES = {
  startTime: 'startTime',
  endTime: 'endTime',
  analytics: 'analytics',
  cstor: 'cstor',
  nmName: 'nmName',
  cidr: 'cidr',
};

export const DB_SCHEMA_TAGS = {
  nmName: 'network_monitor_name',
  cstorName: 'cstor_name',
};

export const DEFAULT_DOWNLOAD_RESPONSE: DownloadResponse = {
  uniqueName: '',
  startTime: 0,
  endTime: 0,
  nmName: '',
  cstor: [],
  cstorIP: [],
  cidr: [],
};

export const DEFAULT_DOWNLOAD_STATUS: DownloadStatus = {
  started: false,
  completed: false,
  status: '',
  color: '',
};

export const DOWNLOAD_STATUS_PROPERTIES = {
  started: 'started',
  completed: 'completed',
  status: 'status',
  color: 'color',
};

export const RESPONSE_PROPERTIES = {
  uniqueName: 'uniqueName',
  startTime: 'startTime',
  endTime: 'endTime',
  nmName: 'nmName',
  cstor: 'cstor',
  cstorIP: 'cstorIP',
  cidr: 'cidr',
};

export const RESPONSE_MESSAGE: { [key: string]: string } = {
  UNAUTHORIZED: 'Unauthorized',
  STATUS_SUCCESS: 'SUCCESS',
  ALREADY_SCHEDULED: 'already',
  PREVIOUSLY_SCHEDULED: 'Was previously scheduled.',
  NO_MATCHING_DOWNLOAD: 'Status update cannot find matching collection.',
  DONE: 'done',
};

// options for configurations
export enum LayoutType {
  NETWORK_MONITOR = 'Network Monitor',
  IPCIDR_WITH_NETWORK_MONITOR = 'Network Monitor and IP/CIDR',
  ANALYTICS_IPCIDR_WITH_CSTOR = 'Analytics, cStor and IP/CIDR ',
}

export enum DOWNLOAD_STATES {
  DISCONNECT = 'cClear not setup or unauthorized. Please login to a configured cClear.',
  INITIALIZED = 'Initial status',
  PRE_ERROR = 'Fix errors before continue',
  PRE_WARNING = 'Out of recommended range (20s - 300s)',
  PRE_READY = 'Ready to collect',
  DOWNLOAD_FAIL = 'Fail to collect ',
  DOWNLOAD_SUCCESS = 'Scheduled',
  DOWNLOAD_STATUS = 'Status update',
  COMPLETE = 'Finished analytics collecting',
}

export const WARNING_TOOLTIPS: { [key: string]: string } = {
  emptyResults:
    'Empty Network Monitor/cStor returned from query. Update to longer timerange or check the query format. See ReadMe (?) for details on valid query setup.',
  incorrectMonitorResults:
    'Incorrect Network Monitor query setup. Make sure it is a "Time series" query group by "network_monitor_name" and alias as "<MONITOR_$tag_network_monitor_name>". Refer to Help (?) for more information.',
  incorrectCstorResults:
    'Incorrect cStor query setup. Make sure it is a "Time series" query group by "cstor_name" and alias as "<CSTOR_$tag_cstor_name>". Refer to Help (?) for more information.',
  resultsInTable: 'Query results formatted as table. Change query results formatting to time series.',
  usesDefaultQuery: 'Panel query is not set up. See ReadMe (?) for details on valid query setup.',
  emptyAnalytics: 'Analytics Type is not set.',
  emptyNMName: 'Network Monitor is not set or the tied monitor does not exist. Select one below instead or check the tie variable or the query results.',
  emptyCstor: 'cStor is not set or the tied cStor does not exist. Select one below instead or check the tied variable or the query results.',
  emptyCidr: 'IP/CIDR cannot be empty. Type in or select a Network Monitor (if visible) to set it.',
  invalidCidr:
    'Incorrect IP/CIDR format: it should be a valid IPv4 address or CIDR, for example "127.0.12.3" or "214.12.21.12/8"',
};

export const RESPONSE_KEY: APIKeyType = {
  uniqueName: 'unique_name',
  cstorIP: 'cstor_ips',
  status: 'status',
  nmName: 'network_monitor_name',
  cstor: 'cstor_names',
  cidr: 'cidr',
  startTime: 'start_time',
  started: 'started',
  completed: 'completed',
  endTime: 'end_time',
  data: 'data',
  onDemandNM: 'on-demand-monitors',
  reason: 'reason',
  message: 'message',
};

// Timer in seconds
export const TIMER_INTERVAL = 2;

// prefix for network monitor name of the on demand analytics. 
// the exact value decided by cClear download. 
// this panel has to apply it when matching a download status with the exact request.
export const UNIQUE_NAME_NM_PREFIX = 'on-demand';

export const defaultQueryWarning: QueryWarning = {
  usesDefaultQuery: false,
  emptyResults: false,
  resultsInTable: false,
  incorrectMonitorResults: false,
  incorrectCstorResults: false,
};

