import React from 'react';
import { cx } from '@emotion/css';
import { Drawer, ClipboardButton } from '@grafana/ui';
import { WithStyles } from 'ondemand/components/hoc/WithStyles';
import { InfoSection } from './InfoSection';
import { Styles } from 'types';

const timeRange = require('ondemand/assets/img/time-range.png');
const monitorQueryBuilder = require('ondemand/assets/img/monitor-query-builder.png');
const monitorQueryText = require('ondemand/assets/img/monitor-query-text.png');
const MONITOR_ALIAS = `<MONITOR_$tag_network_monitor_name>`;
const MONITOR_QUERY_TEXT = `SELECT first("resolution_s") FROM "tcp_open_summary_application_port" WHERE $timeFilter GROUP BY
 "network_monitor_name" fill(none)`;
const cstorQueryBuilder = require('ondemand/assets/img/cstor-query-builder.png');
const cstorQueryText = require('ondemand/assets/img/cstor-query-text.png');
const CSTOR_ALIAS = `<CSTOR_$tag_cstor_name>`;
const CSTOR_QUERY_TEXT = `SELECT first("resolution_s") FROM "tcp_open_summary_application_port" WHERE $timeFilter GROUP BY
  "cstor_name" fill(none)`;

interface Props {
  closeDrawer: () => void;
  styles: Styles;
}

const BaseInfoPanel: React.FC<Props> = ({ closeDrawer, styles }) => {
  return (
    <Drawer
      scrollableContent
      expandable={true}
      title="Settings"
      subtitle="Help with setting up cPacket Analytics panel"
      onClose={closeDrawer.bind(this)}
    >
      <div className={styles.InfoPanel}>
        <InfoSection title="Panel functions">
          <ul>
            <li>
              Downloading analytics data with a combination of selected Analytics Type, cStors, Network Monitors and
              IP/CIDR.
            </li>
            <ul style={{ marginLeft: '2rem' }}>
              <li>
                <span className={styles.infoInstruction}>Time range:</span> select the timerange on the top right
                corder.
              </li>
              <li>
                <span className={styles.infoInstruction}>Analytic Type</span>, if applicable: select from the visible
                dropdown or configure from the &quot;Display&quot; section in &quot;Edit&quot; mode.
              </li>
              <li>
                <span className={styles.infoInstruction}>Network Monitor</span>, if applicable: select from the visible
                dropdown or tie to a dashboard variable.
              </li>
              <li>
                <span className={styles.infoInstruction}>cStors</span>, if applicable: select from the visible dropdown
                or tie to a dashboard variable. Note that it supports multiple selections.
              </li>
              <li>
                <span className={styles.infoInstruction}>IP/CIDR</span>, if applicable: type in or tie to a dashboard
                variable
              </li>
            </ul>
          </ul>
        </InfoSection>
        <InfoSection title="Notes on usage">
          <ul>
            <li>A few notes on using this panel.</li>
            <ul style={{ marginLeft: '2rem' }}>
              <li>
                Long timerange causes long waiting time. A preferred range is between 20s-5m. It shows warning message
                outside of preferred range. It askes for comfirmation if longer than 5m.
              </li>
              <li>
                Requested Network Monitor, cStors and IP/CIDR can come from selections, manual type in or dashboard
                variable bindings.
              </li>
              <li>
                The list of available Network Monitors and cStors can come from either configured queries or, if queries
                not provided, from the connected cClear.
              </li>
            </ul>
          </ul>
        </InfoSection>

        <InfoSection title="Setting up query">
          <span>
            You can set up a query to get a list of Network Monitors or a list of cStors depending on which of the two
            are visible. You can also have both in one query to cover all cases. If a query is not setup, the list will
            come from the connecting cClear.{' '}
          </span>
          <br />
          <br />
          <ul>
            <li>
              <h4>Setting up a Network Monitor query</h4>
              <p>Example Network Monitor query from the query builder:</p>
              <img
                className={cx(styles.verticalSpacing, styles.screenshotMaxWidth)}
                src={monitorQueryBuilder}
                alt="Screenshot showing query Group By settings set to 'tag(monitor)'"
              />
            </li>
            <li>
              <p>Example Network Monitor query from the query text editor:</p>
              <img
                className={cx(styles.verticalSpacing, styles.screenshotMaxWidth)}
                src={monitorQueryText}
                alt="Screenshot showing text edit version of query editor"
              />
              <ClipboardButton variant="secondary" getText={() => MONITOR_QUERY_TEXT} className={styles.clipboardBtn}>
                Copy to clipboard
              </ClipboardButton>
            </li>
            <li>
              <p>Required ALIAS BY setting:</p>
              <code className={styles.codeBlock}>{MONITOR_ALIAS}</code>
              <ClipboardButton variant="secondary" getText={() => MONITOR_ALIAS} className={styles.clipboardBtn}>
                Copy to clipboard
              </ClipboardButton>
            </li>
          </ul>
          <br />
          <br />
          <ul>
            <li>
              <h4>Setting up a cStor query</h4>
              <p>Example cStor query from the query builder:</p>
              <img
                className={cx(styles.verticalSpacing, styles.screenshotMaxWidth)}
                src={cstorQueryBuilder}
                alt="Screenshot showing query Group By settings set to 'tag(cstor)'"
              />
            </li>
            <li>
              <p>Example cStor query from the query text editor:</p>
              <img
                className={cx(styles.verticalSpacing, styles.screenshotMaxWidth)}
                src={cstorQueryText}
                alt="Screenshot showing text edit version of query editor"
              />
              <ClipboardButton variant="secondary" getText={() => CSTOR_QUERY_TEXT} className={styles.clipboardBtn}>
                Copy to clipboard
              </ClipboardButton>
            </li>
            <li>
              <p>Required ALIAS BY setting:</p>
              <code className={styles.codeBlock}>{CSTOR_ALIAS}</code>
              <ClipboardButton variant="secondary" getText={() => CSTOR_ALIAS} className={styles.clipboardBtn}>
                Copy to clipboard
              </ClipboardButton>
            </li>
          </ul>
          <br />
          <br />
          <ul>
            <li>
              <h4>Example query time range:</h4>
              <img
                className={cx(styles.verticalSpacing, styles.screenshotMaxWidth)}
                src={timeRange}
                alt="Screenshot showing selection of time range for current dashboard"
              />
            </li>
          </ul>
        </InfoSection>
      </div>
    </Drawer>
  );
};

export const InfoPanel = WithStyles(BaseInfoPanel);
