import { HorizontalGroup, InlineField, Input, MultiSelect, Select, VerticalGroup } from '@grafana/ui';
import React from 'react';
import { Selections, SimpleOptions, Validation, ParameterConfig } from 'ondemand/types';
import { DOWNLOAD_STATES, DOWNLOAD_REQUEST_LABEL, DEFAULT_SELECTIONS } from '../../../constants';
import { Styles } from 'types';
import { WithStyles } from 'ondemand/components/hoc/WithStyles';
import { SelectableValue } from '@grafana/data';

interface Props {
  styles: Styles;
  monitors: Array<SelectableValue<string>>;
  cstors: Array<SelectableValue<string>>;
  analytics: Array<SelectableValue<string>>;
  enabledConfigs: ParameterConfig;
  selections: Selections;
  setSelections: Function;
  validation: Validation;
  states: DOWNLOAD_STATES;
  replaceVariables: Function;
  options: SimpleOptions;
}

const BaseRequestInfo: React.FC<Props> = ({
  styles,
  monitors,
  cstors,
  analytics,
  enabledConfigs,
  selections,
  setSelections,
  validation,
  states,
  replaceVariables,
  options,
}) => {
  const disabled = [
    DOWNLOAD_STATES.DOWNLOAD_SUCCESS,
    DOWNLOAD_STATES.DOWNLOAD_STATUS,
    DOWNLOAD_STATES.DISCONNECT,
  ].includes(states);
  return (
    <div className={styles.spaceTop}>
      <VerticalGroup justify="center" align="center">
        {enabledConfigs.analyticsType && (
          <HorizontalGroup>
            <InlineField
              label={DOWNLOAD_REQUEST_LABEL.analyticsType}
              className={styles.selectField}
              disabled={disabled}
              invalid={!validation.analyticsType}
              tooltip='Available list comes from cClear. To set the same one every time, go into edit mode and select one to save.'
            >
              <Select
                width={45}
                disabled={disabled}
                invalid={!validation.analyticsType}
                className={styles.inlineEditField}
                onChange={(v: SelectableValue<string>) => {
                  setSelections((prev: Selections) => {
                    return { ...prev, analyticsType: v };
                  });
                }}
                options={analytics}
                value={selections.analyticsType}
              />
            </InlineField>
          </HorizontalGroup>
        )}
        {enabledConfigs.nmName && (
          <HorizontalGroup>
            <InlineField
              label={DOWNLOAD_REQUEST_LABEL.nmName}
              invalid={!validation.nmName}
              className={styles.selectField}
              disabled={disabled}
              tooltip='Available list comes from queries or cClear (if queries are not set). Selected value can be tied to a template variable or manually selected. To properly set a query for use, click on the ? mark above for help.'
            >
              <Select
                width={45}
                disabled={disabled}
                invalid={!validation.nmName}   
                className={styles.inlineEditField}
                onChange={(v: SelectableValue<string>) => {
                  setSelections((prev: Selections) => {
                    return { ...prev, nmName: v };
                  });
                }}
                options={monitors}
                value={selections.nmName}
              />
            </InlineField>
          </HorizontalGroup>
        )}
        {enabledConfigs.cStorName && (
          <HorizontalGroup>
            <InlineField
              label={DOWNLOAD_REQUEST_LABEL.cStor}
              invalid={!validation.cStorName}
              className={styles.selectField}
              disabled={disabled}
              tooltip='Available list comes from queries or cClear (if queries are not set). Selected value can be tied to a template variable or manually selected. To properly set a query for use, click on the ? mark above for help.'
            >
              <MultiSelect
                width={45}
                invalid={!validation.cStorName}
                className={styles.inlineEditField}
                disabled={disabled}
                onChange={(v: Array<SelectableValue<string>>) => {
                  setSelections((prev: Selections) => {
                    return { ...prev, cStorName: v };
                  });
                }}
                options={cstors}
                value={selections.cStorName}
              />
            </InlineField>
          </HorizontalGroup>
        )}
        {enabledConfigs.nmCidr && (
          <HorizontalGroup>
            <InlineField
              label={DOWNLOAD_REQUEST_LABEL.ipCider}
              className={styles.selectField}
              invalid={!validation.nmCidr}
              disabled={disabled}
              tooltip='Default is set to all IPs: 0.0.0.0/0. Value can be typed in, set by a selected Network Monitor, or tied to a template variable. Priority: tied variable -> Network Monitor CIDR -> default. Type in anytime to override all.'
            >
              <Input
                width={45}
                disabled={disabled}
                className={styles.inlineEditField}
                invalid={!validation.nmCidr}
                type="text"
                value={selections.nmCidr}
                placeholder={
                  replaceVariables(options.cidr).trim().length > 0 ? replaceVariables(options.cidr) : '127.0.0.1'
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value;
                  setSelections((prev: Selections) => {
                    return { ...prev, nmCidr: value };
                  });
                }}
                onFocus={(e: React.FocusEvent<HTMLElement>) => {
                   if (!selections.nmCidr || selections.nmCidr.trim().length ===0 ) {
                   setSelections((prev: Selections) => {
                     return { ...prev, nmCidr: DEFAULT_SELECTIONS.nmCidr };
                   });}
                }}
              />
            </InlineField>
          </HorizontalGroup>
        )}
        {enabledConfigs.cstorCidr && (
          <HorizontalGroup>
            <InlineField
              label={DOWNLOAD_REQUEST_LABEL.ipCider}
              className={styles.selectField}
              invalid={!validation.cstorCidr || !validation.notEmptyCstorCidr}
              disabled={disabled}
              tooltip='Default is set to all IPs: 0.0.0.0/0. Value can be typed in, set by a selected Network Monitor (if empty at selection), or tied to a template variable. Priority: tied variable -> default. Type in anytime to override all.'
            >
              <Input
                width={45}
                disabled={disabled}
                invalid={!validation.cstorCidr || !validation.notEmptyCstorCidr}
                className={styles.inlineEditField}
                type="text"
                value={selections.cstorCidr}
                placeholder={
                  replaceVariables(options.cidr).trim().length > 0 ? replaceVariables(options.cidr) : '127.0.0.1'
                }
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value;
                  setSelections((prev: Selections) => {
                    return { ...prev, cstorCidr: value };
                  });
                }}
                onFocus={(e: React.FocusEvent<HTMLElement>) => {
                   if (!selections.cstorCidr || selections.cstorCidr.trim().length ===0 ) {
                   setSelections((prev: Selections) => {
                     return { ...prev, cstorCidr: DEFAULT_SELECTIONS.cstorCidr };
                   });}
                }}
              />
            </InlineField>
          </HorizontalGroup>
        )}
      </VerticalGroup>
    </div>
  );
};

export const RequestInfo = WithStyles(BaseRequestInfo);
