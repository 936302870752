import React, { useRef, useState, useEffect } from 'react';
import { DownloadResponse, DownloadStatus, ParameterConfig } from 'ondemand/types';
import {
  DOWNLOAD_STATES,
  DOWNLOAD_STATUS_PROPERTIES,
  RESPONSE_KEY,
  RESPONSE_MESSAGE,
  RESPONSE_PROPERTIES,
  TIMER_INTERVAL,
} from 'ondemand/constants';
import { WithStyles } from 'ondemand/components/hoc/WithStyles';
import { Styles } from 'types';
import { css, cx } from '@emotion/css';
import { Drawer, HorizontalGroup, IconButton, VerticalGroup } from '@grafana/ui';
import { Queue } from './Queue';
interface Props {
  downloadResponse: DownloadResponse;
  states: DOWNLOAD_STATES;
  setStates: Function;
  downloadStatus: DownloadStatus;
  setDownloadStatus: Function;
  enabledConfigs: ParameterConfig;
  timeElapsed: number;
  toState: Function;
  styles: Styles;
}

const BaseStatus: React.FC<Props> = ({
  // the response from the original download request: to compare with status response and update its status
  downloadResponse,
  states,
  setStates,
  downloadStatus,
  setDownloadStatus,
  enabledConfigs,
  timeElapsed,
  toState,
  styles,
}) => {
  const [complteStatus, setCompleteStatus] = useState<string>('');
  const [refreshPrompt, setRefreshPrompt] = useState<string>('');
  const [queueOpen, setQueueOpen] = useState(false);
  const [downloadQueue, setDownloadQueue] = useState<Array<{ dlResponse: DownloadResponse; dlStatus: DownloadStatus }>>(
    []
  );
  const statusTask = useRef<number>();
  const queueTask = useRef<number>();

  const presetConfigurationField = css`
    margin-top: 20px;
    font-style: italic;
    text-align: center;
    margin-bottom: 12px;
  `;

  function updateDownloadQueue(responseData: any) {
    let queue: Array<{ dlResponse: DownloadResponse; dlStatus: DownloadStatus }> = [];
    responseData.data[RESPONSE_KEY.onDemandNM].forEach((res: { [x: string]: any }) => {
      const dlResponse = {
        [RESPONSE_PROPERTIES.uniqueName as keyof DownloadResponse]: res[RESPONSE_KEY.uniqueName],
        [RESPONSE_PROPERTIES.startTime as keyof DownloadResponse]: res[RESPONSE_KEY.startTime],
        [RESPONSE_PROPERTIES.endTime as keyof DownloadResponse]: res[RESPONSE_KEY.endTime],
        [RESPONSE_PROPERTIES.nmName as keyof DownloadResponse]: res[RESPONSE_KEY.nmName],
        [RESPONSE_PROPERTIES.cstor as keyof DownloadResponse]: res[RESPONSE_KEY.cstor],
        [RESPONSE_PROPERTIES.cstorIP as keyof DownloadResponse]: res[RESPONSE_KEY.cstorIP],
        [RESPONSE_PROPERTIES.cidr as keyof DownloadResponse]: res[RESPONSE_KEY.cidr],
      };
      const dlStatus = {
        [DOWNLOAD_STATUS_PROPERTIES.started as keyof DownloadStatus]: res[RESPONSE_KEY.started],
        [DOWNLOAD_STATUS_PROPERTIES.completed as keyof DownloadStatus]: res[RESPONSE_KEY.completed],
        [DOWNLOAD_STATUS_PROPERTIES.status as keyof DownloadStatus]: res[RESPONSE_KEY.status],
        [DOWNLOAD_STATUS_PROPERTIES.color as keyof DownloadStatus]: styles.downloadStatus,
      };
      queue.push({ dlResponse: dlResponse as DownloadResponse, dlStatus: dlStatus as DownloadStatus });
    });
    setDownloadQueue(queue);
  }
  const toggleCollapse = () => {
    if (!queueOpen) {
      fetchQueue();
      queueTask.current = window.setInterval(fetchQueue, TIMER_INTERVAL * 1000);
    } else {
      window.clearInterval(queueTask.current);
    }
    setQueueOpen(!queueOpen);
  };

  const fetchQueue = () => {
    window
      .fetch('/api/epg_fr/on_demand_monitor/')
      .then((resp: Response) => {
        return resp.json();
      })
      .then(responseData => {
        updateDownloadQueue(responseData);
      })
      .catch(error => console.error(error));
  }

  const equalStringArrays = (arr1: string[], arr2: string[]) => {
    if (!arr1 || !arr2 || (arr1.length !== arr2.length)) {
      return false;
    } else {
      arr1.sort();
      arr2.sort();
      return arr1.every((item, index) => item === arr2.at(index));
    }
  }

  const fetchStatus = () => {
    window
      .fetch('/api/epg_fr/on_demand_monitor/')
      .then((resp: Response) => {
        if (resp.status === 200 || resp.status === 400) {
          return resp.json();
        } else {
          console.error(`error: ${resp.status}`);
          statusTask && window.clearInterval(statusTask.current);
          toState(DOWNLOAD_STATES.COMPLETE, {
            [DOWNLOAD_STATUS_PROPERTIES.status as keyof DownloadStatus]: `${resp.status}:${resp.statusText}`,
            [DOWNLOAD_STATUS_PROPERTIES.color as keyof DownloadStatus]: styles.downloadError,
          });
        return undefined;
        }
      })
      .then(responseData => {
        if (!responseData) {return;}
        // if queue lengh == 0: finished and reload
        const queueLength = responseData.data[RESPONSE_KEY.onDemandNM].length;
        if (queueLength === 0) {
          statusTask && window.clearInterval(statusTask.current);
          toState(DOWNLOAD_STATES.COMPLETE, {
            [DOWNLOAD_STATUS_PROPERTIES.status as keyof DownloadStatus]: RESPONSE_MESSAGE.DONE,
            [DOWNLOAD_STATUS_PROPERTIES.color as keyof DownloadStatus]: styles.downloadStatus,
          });
          return;
        }
        // otherwise, find the matching download from the queue and check its status
        const download = responseData.data[RESPONSE_KEY.onDemandNM].find((downloadQueueItem: { [x: string]: string }) => {
          try {
            if (downloadQueueItem[RESPONSE_KEY.uniqueName] === downloadResponse.uniqueName) {
              return downloadQueueItem;
            } else {
              // time
              let isFound =
                Number(downloadQueueItem[RESPONSE_KEY.startTime]) * 1000 === Number(downloadResponse.startTime) &&
                Number(downloadQueueItem[RESPONSE_KEY.endTime]) * 1000 === Number(downloadResponse.endTime);
              // nm
              if (enabledConfigs.nmName) {
                isFound = isFound && downloadQueueItem[RESPONSE_KEY.nmName] === downloadResponse.nmName;
              }
              // cidr
              if (!!downloadResponse.cidr) {
                isFound =
                  isFound && equalStringArrays((downloadQueueItem[RESPONSE_KEY.cidr] as unknown) as string[], downloadResponse.cidr);
              }
              // cstor
              if (enabledConfigs.cStorName) {
                isFound =
                  isFound && equalStringArrays((downloadQueueItem[RESPONSE_KEY.cstor] as unknown) as string[], downloadResponse.cstor);
              }
              if (isFound) {
                return downloadQueueItem;
              }
            }
          } catch (error) {
            console.error(`errror: ${error}`);
            statusTask && window.clearInterval(statusTask.current);
            toState(DOWNLOAD_STATES.COMPLETE, {
              [DOWNLOAD_STATUS_PROPERTIES.status as keyof DownloadStatus]: `can not find a matching collection from the queue.`,
              [DOWNLOAD_STATUS_PROPERTIES.color as keyof DownloadStatus]: styles.downloadError,
            });
          }
          return undefined;
        });

        if (!download) {
          statusTask && window.clearInterval(statusTask.current);
          toState(DOWNLOAD_STATES.COMPLETE, {
            [DOWNLOAD_STATUS_PROPERTIES.status as keyof DownloadStatus]: RESPONSE_MESSAGE.NO_MATCHING_DOWNLOAD,
            [DOWNLOAD_STATUS_PROPERTIES.color as keyof DownloadStatus]: styles.downloadWarning,
          });
        } else {
          const status = download[RESPONSE_KEY.status];
          setDownloadStatus((prev: DownloadStatus) => {
            return {
              [DOWNLOAD_STATUS_PROPERTIES.started as keyof DownloadStatus]: download[RESPONSE_KEY.started],
              [DOWNLOAD_STATUS_PROPERTIES.completed as keyof DownloadStatus]: download[RESPONSE_KEY.completed],
              [DOWNLOAD_STATUS_PROPERTIES.status as keyof DownloadStatus]: `${status
                .charAt(0)
                .toUpperCase()}${status.slice(1)}`,
              [DOWNLOAD_STATUS_PROPERTIES.color as keyof DownloadStatus]: styles.downloadStatus,
            };
          });
          if (download[RESPONSE_KEY.status].includes(RESPONSE_MESSAGE.DONE)) {
            statusTask && window.clearInterval(statusTask.current);
            toState(DOWNLOAD_STATES.COMPLETE, {
              [DOWNLOAD_STATUS_PROPERTIES.status as keyof DownloadStatus]: download[RESPONSE_KEY.status],
              [DOWNLOAD_STATUS_PROPERTIES.color as keyof DownloadStatus]: styles.downloadStatus,
            });}
        }
      })
      .catch(e => {
        console.error(`e: ${e}`);
        statusTask && window.clearInterval(statusTask.current);
        toState(DOWNLOAD_STATES.COMPLETE, {
          [DOWNLOAD_STATUS_PROPERTIES.status as keyof DownloadStatus]: e,
          [DOWNLOAD_STATUS_PROPERTIES.color as keyof DownloadStatus]: styles.downloadError,
        });
      });
  };

  useEffect(() => {
    if (states === DOWNLOAD_STATES.COMPLETE){
      if (downloadStatus.color === styles.downloadStatus || downloadStatus.color === styles.downloadWarning){
        setCompleteStatus(`Finished fetching analytics in ${timeElapsed} seconds: `);
        setRefreshPrompt('Refresh the dashboard to show data.');
      } else if (downloadStatus.color === styles.downloadError){
        setCompleteStatus(`Error after ${timeElapsed} seconds: `);
        setRefreshPrompt('');
      } 
    } else {
      complteStatus.length>0 && setCompleteStatus('');
      refreshPrompt.length>0 && setRefreshPrompt('');
    }
    // change to 'status' 2s after 'success' and start monitorning status'
    if ([DOWNLOAD_STATES.DOWNLOAD_SUCCESS].includes(states)) {
      window.setTimeout(() => {
        setStates(DOWNLOAD_STATES.DOWNLOAD_STATUS);
        statusTask.current = window.setInterval(fetchStatus, TIMER_INTERVAL * 1000);
      }, 1000);
    }

    // disable because we truly want to listen to only states changes 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [states]);

  return (
    <VerticalGroup>
      <HorizontalGroup justify="center" align="center">
        <div>
          <div className={cx(presetConfigurationField, downloadStatus.color)}>{`${complteStatus}${downloadStatus.status}.`}<br />{refreshPrompt}</div>
        </div>
        <IconButton
          name="info-circle"
          className={styles.drawerButton}
          onClick={toggleCollapse.bind(this)}
          size="xl"
          tooltip="Show Active Analytics Queue"
        />
      </HorizontalGroup>
      <HorizontalGroup>
        {queueOpen && (
          <Drawer
            scrollableContent
            expandable={true}
            closeOnMaskClick={true}
            width={'330px'}
            title="Analytics Queue"
            subtitle="The list of active analytics collection queue"
            onClose={() => {
              window.clearInterval(queueTask.current);
              setQueueOpen(false);
            }}
          >
            <VerticalGroup spacing="lg">
              {downloadQueue.length === 0 ? (
                <HorizontalGroup justify="center">
                  <h4>The queue is empty.</h4>
                </HorizontalGroup>
              ) : (
                downloadQueue.map((download: { dlResponse: DownloadResponse; dlStatus: DownloadStatus }, index) => (
                  <Queue
                    key={index}
                    title={`Analytics #${index + 1}`}
                    downloadResponse={download.dlResponse}
                    downloadStatus={download.dlStatus}
                    styles={styles}
                  />
                ))
              )}
            </VerticalGroup>
          </Drawer>
        )}
        {/* </Collapse> */}
      </HorizontalGroup>
    </VerticalGroup>
  );
};

export const Status = WithStyles(BaseStatus);
