import React, { useState, useEffect } from 'react';
import { PanelProps, SelectableValue, TimeRange } from '@grafana/data';
import {
  DownloadResponse,
  DownloadStatus,
  NetworkMonitor,
  ParameterConfig,
  Selections,
  SimpleOptions,
  Validation,
  QueryWarning, Styles
} from 'ondemand/types';
import {
  DB_SCHEMA_TAGS,
  DEFAULT_DOWNLOAD_RESPONSE,
  DEFAULT_DOWNLOAD_STATUS,
  DEFAULT_SELECTIONS,
  DEFAULT_VALIDATION,
  DEFAULT_ENABLED_CONFIGS,
  DOWNLOAD_STATES,
  LayoutType,
  RESPONSE_MESSAGE,
  defaultQueryWarning,
  REGEX_ALL_IPS,
} from 'ondemand/constants';
import { HorizontalGroup, VerticalGroup } from '@grafana/ui';
import { RequestInfo } from './RequestInfo';
import { WithStyles } from 'ondemand/components/hoc/WithStyles';
import { checkQueryResponseValidity, parseEntities } from 'ondemand/parseData';
import { asyncGetAnalyticsOption, asyncGetCstors, asyncGetMonitors } from 'ondemand/apiUtils';
import { PanelHeader } from './PanelHeader';
import { InfoPanel } from './InfoPanel';
import { ErrorWarnings } from './ErrorWarnings';
import { Status } from './Status';
import { Download } from './Download';
import { RefreshEvent } from '@grafana/runtime';

interface Props extends PanelProps<SimpleOptions> {
  styles: Styles;
}

const BasePanel: React.FC<Props> = ({ options, data, styles, replaceVariables, timeRange, eventBus }) => {
  const [analytics, setAnalytics] = useState<Array<SelectableValue<string>> | undefined>(undefined);
  // the list of available monitors (from queries or cClear) to populate the dropdown selection with
  const [monitors, setMonitors] = useState<Array<SelectableValue<string>> | undefined>(undefined);
  // the list of monitors from cClear 
  const [monitorsFromCclear, setMonitorsFromCclear] = useState<NetworkMonitor[] | undefined>(undefined);
  // the list of available cStors (from queries or cClear) to populate the dropdown selection with
  const [cstors, setCstors] = useState<Array<SelectableValue<string>>>();
  // the list of cStors from cClear
  const [cstorsFromCclear, setCstorsFromCclear] = useState<Array<SelectableValue<string>> | undefined>(undefined);
  const [selections, setSelections] = useState<Selections>(DEFAULT_SELECTIONS);
  const [enabledConfigs, setEnabledConfigs] = useState<ParameterConfig>(DEFAULT_ENABLED_CONFIGS);
  const [validation, setValidation] = useState<Validation>(DEFAULT_VALIDATION);
  const [queryResultsValid, setQueryResultsValid] = useState(true);
  const [queryWarning, setQueryWarning] = useState<QueryWarning>(defaultQueryWarning);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [states, setStates] = useState<DOWNLOAD_STATES>(DOWNLOAD_STATES.INITIALIZED);
  const [downloadResponse, setDownloadResponse] = useState<DownloadResponse>(DEFAULT_DOWNLOAD_RESPONSE);
  const [downloadStatus, setDownloadStatus] = useState<DownloadStatus>(DEFAULT_DOWNLOAD_STATUS);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [prevTimeRange, setPrevTimerange] = useState<TimeRange>(timeRange);
  const isIPValid = (ip: string): boolean => {
    const regex = /^(?<ip>(?:(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))$/;
    const port = ip.split('/')[1];
    const regIP = ip.split('/')[0];
    return regex.test(ip) || (regex.test(regIP) && port.trim().length > 0 && (port ? Number(port) <= 32 : true));
  };

  // TODO: differentiate between different errors: some may allow to proceed of the download. Currently disable with all API errors
  const handleDownloadError = (msg: string, error: any) => {
    console.error(`${msg}: ${error}`);
    const status = error.message === RESPONSE_MESSAGE.UNAUTHORIZED ? DOWNLOAD_STATES.DISCONNECT : error;
    toState(DOWNLOAD_STATES.DISCONNECT, { status: status, color: styles.downloadError });
  };

  const toState = (state: DOWNLOAD_STATES, status: Partial<DownloadStatus>) => {
    setStates(state);
    setDownloadStatus((prev: DownloadStatus) => {
      return {
        ...prev,
        ...status,
      };
    });
  };

  const equalMonitorsCstors = (arr1: Array<SelectableValue<string>> | undefined, arr2: Array<SelectableValue<string>> | undefined) => {
    if (!arr1 || !arr2 || (arr1.length !== arr2.length)) {
      return false;
    } else {
      arr1.sort();
      arr2.sort();
      return arr1.every((item, index) => item === arr2.at(index));
    }
  };

  // transition states according to validation and others
  const updateStatus = () => {
    // if not connected, don't care about state transitions
    if (states === DOWNLOAD_STATES.DISCONNECT || states === DOWNLOAD_STATES.DOWNLOAD_STATUS) {
     return;
   }
   if (
     (!enabledConfigs.analyticsType || validation.analyticsType) &&
     (!enabledConfigs.cStorName || validation.cStorName) &&
     (!enabledConfigs.nmName || validation.nmName) &&
     (!enabledConfigs.nmCidr || validation.nmCidr) &&
     (!enabledConfigs.cstorCidr || (validation.cstorCidr && validation.notEmptyCstorCidr))
   ) {
     // timerange warning
     const range = timeRange.to.valueOf() - timeRange.from.valueOf();
     // advised timerange: 10s - 5m
     if (range <= 0) {
       toState(DOWNLOAD_STATES.PRE_ERROR, { status: 'Timerange must be greater than 1s.', color: styles.downloadError });
     } else if (range > 300 * 1000) {
       toState(DOWNLOAD_STATES.PRE_WARNING, {
         status:
           Math.round(range / 1000) / 60 > 60
             ? `${(Math.round(range / 1000) / 60 / 60).toFixed(1)} hours. ${DOWNLOAD_STATES.PRE_WARNING}`
             : `${(Math.round(range / 1000) / 60).toFixed(1)} minutes. ${DOWNLOAD_STATES.PRE_WARNING}`,
         color: styles.downloadWarning,
       });
     } else {
       toState(DOWNLOAD_STATES.PRE_READY, {
         status: `${Math.round(range / 1000)} seconds. ${DOWNLOAD_STATES.PRE_READY}`,
         color: styles.downloadStatus,
       });
     }
   } else {
     toState(DOWNLOAD_STATES.PRE_ERROR, { status: DOWNLOAD_STATES.PRE_ERROR, color: styles.downloadError });
   }
 };

 // Listen to state updates: auto refresh dashboards on download complete
  useEffect(() => {
    if (states === DOWNLOAD_STATES.COMPLETE) {
      // TODO: Troubleshooting refreshing dashboard on complete. Need to clean up later
      eventBus.subscribe(RefreshEvent, (event)=>{console.log(`Refresh called: ${event}`)});
      
      console.log(`Requesting refresh....`);
      eventBus.publish(new RefreshEvent());
    }}, [eventBus,states]);

  /**
   * On mount: load the list of analytics types, nm and cstors from cClear, update selection if
   * queries are not configured to receive nm, cstors.
   */
  useEffect(() => {
    // get analytics types: update selections according to options.analyticsType or 1st in the list
    if (!analytics) {
      asyncGetAnalyticsOption()
        .then(anaOptions => {
          setAnalytics(anaOptions);
        })
        .catch(error => {
          handleDownloadError('Error fetching analytics types: ', error);
        });
    }
    // get network monitor names and cidr: update monitorsFromCclear, monitors and selections according to data and options
    if (!monitorsFromCclear) {
      asyncGetMonitors()
        .then(response => {
          setMonitorsFromCclear(response);
        })
        .catch(error => {
          handleDownloadError('Error fetching network monitors: ', error);
        });
    }
    // get cstor names: update cstors and selections according to data and options
    if (!cstorsFromCclear) {
      asyncGetCstors()
        .then(response => {
          setCstorsFromCclear(response);
        })
        .catch(error => {
          handleDownloadError('Error fetching cStors: ', error);
        });
    }
  // disable this line because we truly want to run this only for once when mounting
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Update available nm and cStors from queries or cClear
  useEffect(() => {
    // evaluate the query to see if nm and cstor should come from queries
    let fromCclearTemp = !data.request || data.request.targets.length === 0 || data.request?.targets.every(t => t.hide);
    if (!fromCclearTemp && data.request) {
      let fromQueryTemp = false;
      for (let i = 0; i < data.request.targets.length; i++) {
        const t: any = data.request.targets[i];
        const hidden = t.hide;
        const rawQuery = t.rawQuery;
        const query = t.query;
        const measurement = t.measurement;
        if (!hidden) {
          fromQueryTemp = rawQuery
            ? query &&
              (query.toString().includes(DB_SCHEMA_TAGS.nmName) || query.toString().includes(DB_SCHEMA_TAGS.cstorName))
            : measurement;
        }
        if (fromQueryTemp) {
          break;
        }
      }
      fromCclearTemp = !fromQueryTemp;
    }
    let tempMonitors: Array<SelectableValue<string>> = [];
    let tempCstors: Array<SelectableValue<string>> = [];
    // yes, from query
    if (!fromCclearTemp) {
      const queryWarnings = checkQueryResponseValidity(
        data.series,
        options.ltType !== LayoutType.ANALYTICS_IPCIDR_WITH_CSTOR,
        options.ltType === LayoutType.ANALYTICS_IPCIDR_WITH_CSTOR
      );
      const responsesValid = Object.values(queryWarnings).every(value => !value);
      setQueryResultsValid(responsesValid);
      setQueryWarning(queryWarnings);
      [tempCstors, tempMonitors] = parseEntities(data.series);
      !equalMonitorsCstors(monitors,tempMonitors) && setMonitors(tempMonitors);
      !equalMonitorsCstors(cstors, tempCstors) && setCstors(tempCstors);
    } else {
      // no, from cclear
      setQueryResultsValid(true);
      // nm
      if (!!monitorsFromCclear && monitorsFromCclear.length > 0) {
        tempMonitors = monitorsFromCclear.map(nm => {
          return { label: nm.name, value: nm.name };
        });
        !equalMonitorsCstors(monitors, tempMonitors) && setMonitors(tempMonitors);
      }
      // cstors
      if (!!cstorsFromCclear && cstorsFromCclear.length > 0) {
        !equalMonitorsCstors(cstors,cstorsFromCclear) && setCstors(cstorsFromCclear);
      }
    }
  // disable because we don't want to listen to cstors and monitors change: would cause circular updates.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cstorsFromCclear, data, monitorsFromCclear, options.ltType]);

  // Update analytics types
  useEffect(() => {
    if (!analytics) {return;}
    const selected = analytics && analytics.find(analytic => {
        return analytic.value === options.analyticsType;
      });
    setSelections((prev: Selections) => {
      return { ...prev, analyticsType: selected ? selected : analytics ? analytics[0] : undefined };
    });
  }, [analytics, options.analyticsType]);

  // update enabled configs
  useEffect(() => {
    switch (options.ltType) {
      case LayoutType.NETWORK_MONITOR: {
        setEnabledConfigs({ analyticsType: false, nmName: true, cStorName: false, nmCidr: false, cstorCidr: false });
        break;
      }
      case LayoutType.IPCIDR_WITH_NETWORK_MONITOR: {
        setEnabledConfigs({ analyticsType: false, nmName: true, cStorName: false, nmCidr: true, cstorCidr: false });
        break;
      }
      case LayoutType.ANALYTICS_IPCIDR_WITH_CSTOR: {
        setEnabledConfigs({ analyticsType: true, nmName: false, cStorName: true, nmCidr: false, cstorCidr: true });
        break;
      }
      default: {
        console.error('LayoutType is not set.');
      }
    }
  }, [options.ltType]);

  // update nmCidr selection: priority: tied variable -> nm -> default
  useEffect(() => {
    const isFromVariable = options.cidr && options.cidr.trim().length > 0;
    const cidrFromVariable = isFromVariable ? replaceVariables(options.cidr.trim()) : '';
    if (cidrFromVariable && cidrFromVariable.length > 0  && cidrFromVariable.trim() !== REGEX_ALL_IPS) {
      setSelections((prev: Selections) => {
        return { ...prev, nmCidr: cidrFromVariable };
      });
    } else {
      const foundCidr = monitorsFromCclear && monitorsFromCclear.find(nm => nm.name === selections.nmName?.label);
      if (foundCidr) {
        setSelections((prev: Selections) => {
          return { ...prev, nmCidr: foundCidr.cidr[0] };
        });
      } else {
        setSelections((prev: Selections) => {
          return { ...prev, nmCidr: DEFAULT_SELECTIONS.nmCidr };
        });
      }
   }
  // remove unwanted "selections.nmCidr" from the list: would cause circular update if added.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monitorsFromCclear, options.cidr, replaceVariables(options.cidr), selections.nmName]);

  // update cstorCidr selection: priority: tied variable -> default
  useEffect(() => {
    if (!enabledConfigs.cStorName) {return;}
    const isFromVariable = !!options.cidr && options.cidr.trim().length > 0;
    const cidrFromVariable = isFromVariable ? replaceVariables(options.cidr.trim()) : '';
    if (cidrFromVariable && cidrFromVariable.length > 0 && cidrFromVariable.trim() !== REGEX_ALL_IPS) {
      setSelections((prev: Selections) => ({ ...prev, cstorCidr: cidrFromVariable}));
    } else {
      setSelections((prev: Selections) => {
        return { ...prev, cstorCidr: DEFAULT_SELECTIONS.cstorCidr };
      });
    }
    // disable warning of listenting to replaceVariables: actually want to listen to replaceVariables(options.cidr)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledConfigs.cStorName, options.cidr, replaceVariables(options.cidr)]);

  // Update NM selection 
  useEffect(() => {
    if (!enabledConfigs.nmName || !monitors) {return;}
    let selectedMonitor: SelectableValue<string> | undefined;
    const nmSelectionFromVariable = options.nmName && options.nmName.length > 0;
    if (nmSelectionFromVariable) {
      const monitorFromVariable = replaceVariables(options.nmName);
      selectedMonitor = { label: monitorFromVariable, value: monitorFromVariable };
    } else {
      selectedMonitor = (monitors && monitors.length > 0) ? monitors[0] : {};
    }
    setSelections((prev: Selections) => {
      return { ...prev, nmName: selectedMonitor };
    });
    // disable warning of listenting to replaceVariables: actually want to listen to replaceVariables(options.nmName)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledConfigs.nmName, monitors, options.nmName, replaceVariables(options.nmName)]);

  // update cStor selection
  useEffect(() => {
    if (!enabledConfigs.cStorName || !cstors) {return;}
    let selectedCstors: Array<SelectableValue<string>> = [];
    const cstorSelectionFromVariable = options.cStorName && options.cStorName.length > 0;
    if (cstorSelectionFromVariable) {
      const cstorFromVariable = replaceVariables(options.cStorName);
      if (cstorFromVariable) {
        cstorFromVariable.split(',').forEach(cstor => {
          selectedCstors.push({ label: cstor, value: cstor });
        });
      }
    } else {
      if (!cstors) {return;}
      selectedCstors = (cstors && cstors.length > 0) ? [cstors[0]] : [];
    }
    setSelections((prev: Selections) => {
      return { ...prev, cStorName: selectedCstors };
    });
    // disable warning of listenting to replaceVariables: actually want to listen to replaceVariables(options.cStorName)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cstors, enabledConfigs.cStorName, options.cStorName, replaceVariables(options.cStorName)]);

  // validate cStor
  useEffect(() => {
    if (!selections.cStorName) {return;}
    const cstorSelections = cstors?.filter(cstor =>
      selections.cStorName?.find(cs => JSON.stringify(cs) === JSON.stringify(cstor))
    );
    setValidation((prev: Validation) => {
      return { ...prev, cStorName: !!cstorSelections && cstorSelections.length > 0 };
    });
  }, [cstors, selections.cStorName]);

  // validate nm
  useEffect(() => {
    if (!selections.nmName) {return;}
    const nmSelections = monitors?.find((nm) => nm.label === selections.nmName?.label)?.label;
    setValidation((prev: Validation) => {
      return { ...prev, nmName: !!nmSelections && nmSelections.length > 0 };
    });
  }, [monitors, selections.nmName]);

  // validate analytics
  useEffect(() => {
    if (!analytics || !selections.analyticsType) {return;}
    setValidation((prev: Validation) => {
      return { ...prev, analyticsType: !!selections.analyticsType };
    });
  }, [analytics, selections.analyticsType]);

  // validate nm cidr
  useEffect(() => {
    setValidation((prev: Validation) => {
      return { ...prev, nmCidr: !!selections.nmCidr ? isIPValid(selections.nmCidr) : false };
    });
  }, [selections.nmCidr]);

  // validate cStor cidr
  useEffect(() => {
    setValidation((prev: Validation) => {
      return {
        ...prev,
        cstorCidr: !!selections.cstorCidr ? isIPValid(selections.cstorCidr) : true,
        notEmptyCstorCidr: !!selections.cstorCidr && selections.cstorCidr.trim().length > 0,
      };
    });
  }, [selections.cstorCidr]);
  
  useEffect(() => {
    if (timeRange.to.valueOf() === prevTimeRange.to.valueOf() && timeRange.from.valueOf() === prevTimeRange.from.valueOf()){
      return;
    }
    setPrevTimerange(timeRange);
    updateStatus();
   // Disabled because this only handles timerange update.
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [timeRange]);

  useEffect(() => {
   updateStatus();
  // Disabled because not adding the list: states, styles.downloadError, styles.downloadStatus, styles.downloadWarning
  // "styles" are constant and we don't want to listen for "states" update which would cause circular updates.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledConfigs, validation]);

  return (
    <div className={styles.container}>
      <PanelHeader onIconClick={setIsDrawerOpen.bind(this, true)} />

      {DOWNLOAD_STATES.DISCONNECT !== states && (
        <ErrorWarnings
          queryResultsValid={queryResultsValid}
          queryWarning={queryWarning}
          styles={styles}
          validation={validation}
          enabledConfigs={enabledConfigs}
          selections={selections}
        />
      )}

      {isDrawerOpen && <InfoPanel closeDrawer={setIsDrawerOpen.bind(this, false)} />}

      <div>
        <VerticalGroup justify="center" align="center">
          <HorizontalGroup>
            <RequestInfo
              styles={styles}
              monitors={monitors}
              cstors={cstors}
              analytics={analytics}
              enabledConfigs={enabledConfigs}
              selections={selections}
              setSelections={setSelections}
              validation={validation}
              states={states}
              replaceVariables={replaceVariables}
              options={options}
            />
          </HorizontalGroup>
          <HorizontalGroup justify="center" align="center">
            <Status
              downloadResponse={downloadResponse}
              states={states}
              setStates={setStates}
              downloadStatus={downloadStatus}
              setDownloadStatus={setDownloadStatus}
              enabledConfigs={enabledConfigs}
              timeElapsed={timeElapsed}
              toState={toState}
              styles={styles}
            />
          </HorizontalGroup>
          <HorizontalGroup justify="center" align="center">
            <Download
              timeRange={timeRange}
              selections={selections}
              enabledConfigs={enabledConfigs}
              states={states}
              setStates={setStates}
              setDownloadResponse={setDownloadResponse}
              setDownloadStatus={setDownloadStatus}
              timeElapsed={timeElapsed}
              setTimeElapsed={setTimeElapsed}
              toState={toState}
              monitorsFromCclear={monitorsFromCclear}
              styles={styles}
              options={options}
            />
          </HorizontalGroup>
        </VerticalGroup>
      </div>
    </div>
  );
};

export const OndemandPanel = WithStyles(BasePanel);
